<template>
  <v-row dense>
    <v-col cols="12">
      <common-input-text-field
        v-model="internalValue.activity_type"
        placeholder="Activity Type"
        color="primary"
        clearable
        hide-details
        dense
        background-color="#FFFFFF"
        append-icon="mdi-magnify"
      />
    </v-col>
    <!-- <v-col cols="12">
      <common-input-inline-date-picker
        v-model="internalValue.created_on_gte"
        label="Created on From"
        color="primary"
        clearable
        hide-details
        dense
      />
    </v-col>
    <v-col cols="12">
      <common-input-inline-date-picker
        v-model="internalValue.created_on_lte"
        label="Created on To"
        color="primary"
        clearable
        hide-details
        dense
      />
    </v-col> -->
    <v-col cols="12">
      <common-input-text-field
        v-model="internalValue.log"
        placeholder="Log"
        color="primary"
        clearable
        hide-details
        dense
        background-color="#FFFFFF"
        append-icon="mdi-magnify"
      />
    </v-col>
    <v-col cols="12">
      <common-input-text-field
        v-model="internalValue.resource"
        placeholder="Resource"
        color="primary"
        clearable
        hide-details
        dense
        background-color="#FFFFFF"
        append-icon="mdi-magnify"
      />
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ListFilter',

    components: {
    },

    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },

    data: () => ({
    }),

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },
  }
</script>
