<template>
  <div>
    <list-filter
      v-model="internalOptions.filters"
    />
    <v-row class="no-gutters">
      <v-progress-circular
        v-if="loading"
        color="primary"
        indeterminate
        size="30"
      />
      <v-spacer />
      <common-trs-btn
        type="tertiary"
        icon
        class="mr-3"
        @click="$emit('loadData')"
      >
        <v-icon>mdi-refresh</v-icon>
      </common-trs-btn>
    </v-row>

    <v-row>
      <v-col
        v-for="(item, index) in items"
        :key="index"
        cols="12"
      >
        <slot
          name="item"
          :item="item"
        />
      </v-col>
    </v-row>

    <div
      v-if="items.length === 0"
      class="trsText--text text-body-1 text-center"
    >
      No items
    </div>
    <common-trs-data-table-list-pager
      v-model="internalOptions.pager"
    />
  </div>
</template>

<script>
  import ListFilter from './ListFilter'

  export default {
    name: 'List',

    components: {
      ListFilter,
    },

    props: {
      options: {
        type: Object,
        required: true,
      },
      items: {
        type: Array,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
    }),

    computed: {
      internalOptions: {
        get () {
          return this.options
        },
        set (val) {
          this.$emit('update:options', val)
        },
      },
    },

    watch: {
    },

    created () {
    },

    methods: {
      updateItem (id, data) {
        this.$emit('updateItem', id, data)
      },
    },
  }
</script>
<style lang="scss">
</style>
